.photos-container {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photos-style {
  height: 300px;
  width: 350px;
  max-width: 350px;
  max-height: 300px;
  object-fit: cover;
}

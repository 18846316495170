/* Header */
.header{
  background-color: black;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;
}

/* @media (min-width: 576px) {
  .menu-items {
    flex-direction: row;
  }

} */




/* Logo */
.logo{
  display: inline-block;
  color: white;
  font-size: 60px;
  margin-left: 10px;

}

/* Nav menu */
.nav{
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: black;
  overflow: hidden;
  
}

.menu a{
  display: block;
  padding: 30px;
  color: var(--white);

}

.menu a:hover{
  background-color: white;
}

.nav{
  max-height: 0;
  transition: max-height .5s ease-out;
}

/* Menu Icon */

.hamb{
  cursor: pointer;
  float: right;
  padding: 40px 20px;
}


.hamb-line {
  background: white;
  display: block;
  height: 2px;
  position: relative;
  width: 24px;
  
}

.hamb-line::before,
.hamb-line::after{
  background: white;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.hamb-line::before{
  top: 5px;
}

.hamb-line::after{
  top: -5px;
}


.side-menu {
  display: none;
}

/* Toggle menu icon */

.side-menu:checked ~ nav{
  max-height: 100%;
}

.side-menu:checked ~ .hamb .hamb-line {
  background: transparent;
}

.side-menu:checked ~ .hamb .hamb-line::before {
  transform: rotate(-45deg);
  top:0;

}

.side-menu:checked ~ .hamb .hamb-line::after {
  transform: rotate(45deg);
  top:0;
}


/* Responsiveness */

@media (min-width: 768px) {
  .nav{
      max-height: none;
      top: 0;
      position: relative;
      float: right;
      width: fit-content;
      background-color: transparent;

  }

  .menu li{
      float: left;
  }

  .menu a:hover{
      background-color: transparent;
      color: white;
      
  }
  
  .hamb{
      display: none;
  }

}



.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #FBFDFF;
  font-family: 'Inter', sans-serif;
}

.content-container {
  flex: 1;
}

.thousand-orange {
  background-color: #DB6C1F;
}

.thousand-blue {
  background-color: #64A0C8;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.rounded-button {
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.border-button {
  border: 3px solid white;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
}

.primary {
  background-color: #DB6C1F;
  color: #fff;
}

.secondary {
  background-color: #64A0C8;
  color: #333;
}

.container {
  display: flex;
}

.left-section, .right-section {
  flex: 1;
  padding: 0px 20px;
}

/* Responsive styles for smaller screens */
@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .left-section, .right-section {
    flex: none;
  }
}

.font-link {
  font-family: 'Lato', cursive;
}

/* What is Project Week About */

.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.grid-item {
  display: flex;
  font-weight: 500;
  flex-direction: row;
  margin: 10px;
  padding: 10px;
  text-align: center;
  max-width: 400px;
}

.icon {
  /* background-color: #64A0C8; */
  color: white;
  /* width: 40px;
  height: 40px; */
  border-radius: 3px;
  padding: 10px 10px;
}

/* Why attend section */

.why-attend-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-top: 10px;
  color: white;
}

.why-attend-left-section,
.why-attend-right-section {
  flex: 1;
  padding: 30px;
  margin: 5px;
  text-align: center;
  width: 100%;
}

.why-attend-left-section {
  background-color: #DB6C1F; /* Orange background for the left section */
  margin-right: 20px;
}

.why-attend-right-section {
  background-color: #64A0C8; /* Blue background for the right section */
}

@media (max-width: 768px) {
  .why-attend-container {
    flex-direction: column; /* Stack sections on top of each other on smaller screens */
  }

  .why-attend-left-section,
  .why-attend-right-section {
    flex: none; /* Reset the flex properties for stacking */
    margin-right: 0; /* Remove the right margin */
  }

  .why-attend-left-section {
    margin-bottom: 20px; /* Add space between sections */
  }
}

/* Timeline section */

.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 10px 0;
  width: 600px;
  /* background-color: #64A0C8; */
}

.timeline-container::before {
  background-color: #e17b77;
  content: '';
  position: absolute;
  left: 2px;
  width: 4px;
  height: 100%;
}

.timeline-item {
  display: flex;
  padding-left: 30px;
  padding-right: 0;
  position: relative;
  margin: 10px 0;
  width: 100%;
  justify-content: flex-start;

  text-align: left;
  align-items: flex-start;
}

/* .timeline-item:nth-child(odd) {
  align-self: flex-end;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 0;
} */

.timeline-item-content {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  position: relative;
  text-align: left;
  width: 100%;
}

.timeline-item-content::before {
  content: ' ';
  background-color: #fff;
  box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: calc(50% - 7.5px);
  transform: rotate(45deg);
  width: 15px;
  height: 15px;
  right: auto;
  left: -7.5px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.1);
}

/* .timeline-item:nth-child(odd) .timeline-item-content {
  text-align: left;
  align-items: flex-start;
} */

/* .timeline-item:nth-child(odd) .timeline-item-content::after {
  right: auto;
  left: -7.5px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.1);
} */

.timeline-item-content .tag {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  top: 5px;
  left: 5px;
  letter-spacing: 1px;
  padding: 5px;
  position: absolute;
  text-transform: uppercase;
}

/* .timeline-item:nth-child(odd) .timeline-item-content .tag {
  left: auto;
  right: 5px;
} */

.timeline-item-content time {
  color: #777;
  font-size: 12px;
  font-weight: bold;
}

.timeline-item-content p {
  font-size: 16px;
  line-height: 20px;
  margin: 10px 0;
}


.timeline-item-content .circle {
  background-color: #fff;
  border: 3px solid #e17b77;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 10px);
  left: -35px;
  width: 20px;
  height: 20px;
  z-index: 100;
}

/* .timeline-item:nth-child(odd) .timeline-item-content .circle {
  right: auto;
  left: -40px;
} */

@media only screen and (max-width: 1023px) {
    .timeline-item-content {
        max-width: 100%;
        /* width: 60%; */
    }
}

@media only screen and (max-width: 767px) {
    /* .timeline-item-content,
    .timeline-item:nth-child(odd) .timeline-item-content {
        padding: 15px 10px;
        text-align: center;
        align-items: center;
    } */

    .timeline-container {
      width: 90%;
      margin: 15px 10px;
    }
   
}

/* FAQ section */

.split-accordion {
  display: flex;
  justify-content: space-between;
  padding: 30px 200px;
}

.accordion-left-section,
.accordion-right-section {
  width: 50%;
}

/* Layout for smaller screens */
@media (max-width: 768px) {
  .split-accordion {
    flex-direction: column;
  }

  .accordion-left-section,
  .accordion-right-section {
    width: 100%;
  }
}

.ant-collapse-header-text {
  font-size: 18px;
  font-weight: 500;
}

.ant-collapse-header {
  display: flex;
  align-items: center !important;
  justify-content: center;
  height: "100%"
}

.ant-collapse-content {
  font-size: 16px;
}

/* Checkbox styles */
.ant-checkbox .ant-checkbox-inner {
  border-color: #64A0C8;
}

/* .ant-checkbox-disabled .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  background-color: gray;
  border-color: gray;
}

.ant-checkbox-checked .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  background-color: transparent;
  border-color: red;
} */


/* Terms  */
.box-scroll {
  max-height: 40vh;
  overflow-y: auto;
}

.ant-form-item-required {
  align-items: baseline;
}

/* Modal */
.modalStyle.ant-modal-content {
  background-color: red;
}



/* .box-scroll::-webkit-scrollbar-track {
  margin-top: 10px;
  border-radius: 10px;
  background-color: transparent;
}

.box-scroll::-webkit-scrollbar {
  width: 0.5vw;
  background-color: transparent;
}

.box-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(57, 57, 57, 0.2);
}

.box-scroll::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background-color: rgba(57, 57, 57, 0.3);
}

.box-scroll {
  scrollbar-width: thin;
} */


.custom-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .custom-container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .custom-container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .custom-container {
    width: 1170px;
  }
}

.custom-container1 {
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .custom-container1 {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .custom-container1 {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .custom-container1 {
    width: 1170px;
  }
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
  font-size: inherit;
  left: 10px;
  z-index: 2;
  color: blueviolet;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
  font-size: inherit;
  right: 10px;
  z-index: 2;
  color: blueviolet;
}


.insight-video {
  padding: 0px;
}

@media (min-width: 992px) {
  .insight-video {
    padding: 0px 160px;
    margin: 10px 0px;

  }
}

.top-heading {
  font-weight: 800;
  font-size: 45px;
  line-height: 0.95;
}

@media (min-width: 992px) {
  .top-heading {
    font-size: 60px;
  }
}
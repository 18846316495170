.logos-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.company-logo {
  max-width: 110px;
  height: 100px;
  object-fit: scale-down;
}

/* Media query for responsiveness */
@media (max-width: 1025px) {
  .company-logo {
    max-width: 70px;
    height: 70px;
  }
}

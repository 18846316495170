.logos-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 100px;
}

.logo {
  max-width: 80px;
  height: auto; /* Maintain aspect ratio */
  margin: 10px;
  object-fit: scale-down;
}

.long-logo {
  max-width: 170px;
  height: auto; /* Maintain aspect ratio */
  margin: 10px;
  object-fit: scale-down;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .logo {
    max-width: 60px;
  }

  .long-logo {
    max-width: 130px;
  }

  .logos-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0px 10px;
  }

  
}
